@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@400;500&display=swap');

.footerContainer {
    padding: 30px;
    background-color: rgb(60, 60, 60);
    text-align: center;
}

.footerText {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: white;
}