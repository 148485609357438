@import url(https://fonts.googleapis.com/css?family=DM+Sans:400,500|Jost:400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    width: 100%;
    scroll-behavior: smooth;
}

.mainNav {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.navContainer {
    background: rgb(40, 40, 40) !important;
    margin: 0px !important;
    width: 100% !important;
    max-width: none !important;
}

.navLink {
    margin-left: 3vw !important;
    font-size: 21px;
    color: white !important;
}

.navLink:hover {
    color: #12c8ff !important;
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

.navLogo {
    margin-left: 20px;
}

.navLinkContainer {
    margin-right: 7vw !important;
}
* {
    box-sizing: border-box;
}

.mainBackground {
    color: #2b2c48;
    font-family: "Jost", sans-serif;
    background-image: url("https://images.unsplash.com/photo-1566738780863-f9608f88f3a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2378&q=80");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
}

.card {
    height: 700px;
    margin: auto;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    overflow-x: hidden;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 71%, rgba(102, 205, 171, 1) 84%, rgba(72, 130, 189, 1) 100%);
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
}

.card[data-state="#about"] {
    height: 500px;
    width: 550px;
}

.card[data-state="#about"] .card-main {
    padding-top: 0;
}

.card[data-state="#contact"] {
    height: 480px;
    width: 460px;
}

.card[data-state="#experience"] {
    height: 550px;
    width: 650px;
}

.card.is-active #card-header {
    height: 80px;
}

.card.is-active .card-cover {
    height: 100px;
    top: -50px;
}

.card.is-active .card-avatar {
    -webkit-transform: none;
            transform: none;
    left: 20px;
    width: 50px;
    height: 50px;
    bottom: 10px;
}

.card.is-active .card-fullname,
.card.is-active .card-jobtitle {
    left: 86px;
    -webkit-transform: none;
            transform: none;
}

.card.is-active .card-fullname {
    top: 30px;
    bottom: 18px;
    font-size: 19px;
}

.card.is-active .card-jobtitle {
    bottom: 16px;
    letter-spacing: 1px;
    font-size: 10px;
}

#card-header {
    position: relative;
    display: flex;
    height: 210px;
    flex-shrink: 0;
    width: 100%;
    transition: 0.3s;
    border: none;
}

#card-header * {
    transition: 0.3s;
}

.card-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    height: 200px;
    top: -20%;
    left: 0;
    will-change: top;
    background-size: cover;
    background-position: center;
    -webkit-filter: blur(30px);
            filter: blur(30px);
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    transition: 0.5s;
}

.card-avatar {
    width: 100px;
    height: 100px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-64px);
            transform: translateX(-50%) translateY(-64px);
}

.card-name {
    position: absolute;
    bottom: 0;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    -webkit-transform: translateY(-10px) translateX(-50%);
            transform: translateY(-10px) translateX(-50%);
    left: 50%;
}

.card-title {
    position: absolute;
    bottom: 0;
    font-size: 11px;
    white-space: nowrap;
    font-weight: 500;
    opacity: 0.7;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(10px);
            transform: translateX(-50%) translateY(10px);
}

.card-main {
    position: relative;
    flex: 1 1;
    display: flex;
    padding-top: 10px;
    flex-direction: column;
}

.card-subtitle {
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 8px;
}

.card-content {
    margin-top: 20px;
    padding: 20px;
}

.card-desc {
    line-height: 1.6;
    color: #636b6f;
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
}

.card-social {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 30px;
}

.card-social svg {
    fill: #a5b5ce;
    width: 16px;
    display: block;
    transition: 0.3s;
}

.card-social a {
    color: #8797a1;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-color: rgba(93, 133, 193, 0.05);
    border-radius: 50%;
    margin-right: 20px;
}

.card-social .facebook:hover svg {
    fill: #005ce7;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.card-social .instagram:hover svg {
    fill: #f537f1;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.card-social .linkedin:hover svg {
    fill: #00d5ff;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.card-social a:last-child {
    margin-right: 0;
}

.card-buttons {
    display: flex;
    background-color: #fff;
    margin-top: auto;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
}

.card-buttons button {
    flex: 1 1 auto;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background: 0;
    font-size: 13px;
    border: 0;
    padding: 15px 5px;
    cursor: pointer;
    color: #5c5c6d;
    transition: 0.3s;
    font-family: "Jost", sans-serif;
    font-weight: 500;
    outline: 0;
    border-bottom: 3px solid transparent;
}

.card-buttons button.is-active,
.card-buttons button:hover {
    color: #2b2c48;
    border-bottom: 3px solid #8a84ff;
    background: linear-gradient(to bottom, rgba(127, 199, 231, 0) 0%, rgba(207, 204, 255, 0.2) 44%, rgba(211, 226, 255, 0.4) 100%);
}

.card-section {
    display: none;
}

.card-section.is-active {
    display: block;
    -webkit-animation: fadeIn 0.6s both;
            animation: fadeIn 0.6s both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: translatey(40px);
                transform: translatey(40px);
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        -webkit-transform: translatey(40px);
                transform: translatey(40px);
    }

    100% {
        opacity: 1;
    }
}

.card-timeline {
    margin-top: 30px;
    position: relative;
}

.card-timeline:after {
    background: linear-gradient(to top, rgba(134, 214, 243, 0) 0%, rgba(81, 106, 204, 1) 100%);
    content: "";
    left: 62px;
    width: 2px;
    top: 0;
    height: 100%;
    position: absolute;
    content: "";
}

.card-item {
    position: relative;
    padding-left: 90px;
    padding-right: 20px;
    padding-bottom: 30px;
    z-index: 1;
}

.card-item:last-child {
    padding-right: 50px;
    padding-bottom: 5px;
}

.card-item:after {
    content: attr(data-year);
    width: 10px;
    position: absolute;
    top: 0;
    left: 59px;
    width: 8px;
    height: 8px;
    line-height: 0.6;
    border: 2px solid #fff;
    font-size: 11px;
    text-indent: -35px;
    border-radius: 50%;
    color: rgba(134, 134, 134, 0.7);
    background: linear-gradient(to bottom, #a0aee3 0%, #516acc 100%);
}

.card-item-title {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 5px;
}

.card-item-desc {
    font-size: 13px;
    color: #6f6f7b;
    line-height: 1.5;
    font-family: "DM Sans", sans-serif;
}

.card-contact-wrapper {
    margin-top: 20px;
}

.card-contact {
    width: 400px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #6f6f7b;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
}

.card-contact+.card-contact {
    margin-top: 16px;
}

.card-contact svg {
    flex-shrink: 0;
    width: 30px;
    min-height: 34px;
    margin-right: 12px;
    transition: 0.3s;
    padding-right: 12px;
    border-right: 1px solid #dfe2ec;
}

.contact-me {
    border: 0;
    outline: none;
    background: linear-gradient(to right, rgba(83, 200, 239, 0.8) 0%, rgba(81, 106, 204, 0.8) 96%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    color: #fff;
    padding: 12px 16px;
    width: 100%;
    border-radius: 5px;
    margin-top: 25px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: "Jost", sans-serif;
    transition: 0.3s;
}

#openEmail {
    color: white;
    text-decoration: none;
}
#cardHeader {
    font-family: 'Montserrat', sans-serif;
    color: white;
    margin-bottom: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    background-color: rgb(38, 38, 38);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
}

#cardTextContainer {
    padding: 0;
}

.cardContainer {
    width: 330px;
    border: 12px solid rgb(38, 38, 38);
    font-weight: bold;
    color: white;
    background-color: rgb(38, 38, 38);
}

.cardDescription {
    font-family: 'Montserrat', sans-serif;
    color: rgb(216, 216, 216);
    padding: 0;
    padding-top: 5px;
    padding-bottom: 50px;
    background-color: rgb(38, 38, 38);
    text-align: center;

}

.buttonsContainer {
    background-color: #262626;
}
.languageText {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid;
    border-radius: 10px;
}
.experienceTitle {
    margin-left: 12vw;
    color: rgb(255, 255, 255);
    font-size: 70px;
    padding: 0px;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 5px;
}

.line {
    color: rgb(144, 144, 144);
    width: 76vw;
    border-top: 3px solid rgb(144, 144, 144);
    padding: 0px;
    margin: 0px;
    margin-left: 12vw;
}

.blackBackgroundExperience {
    background: linear-gradient(0deg, rgba(10, 10, 10, 1) 0%, rgb(14, 89, 111) 100%);
}

.blackSpace {
    height: 17vh;
    background-color: rgb(10, 10, 10);
}

.backgroundColorExperience {
    height: 17vh;
    background: linear-gradient(0deg, rgb(14, 89, 111) 0%, rgb(117, 223, 255) 100%);
}

.experienceContainer {
    display: flex;
    justify-content: center;
    padding-right: 0 !important;
    padding-top: 6vh;
    background-color: rgb(10, 10, 10);
}

.experienceContainerInner {
    width: 80%;
}

.row>* {
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 20px;
    margin-bottom: 30px;
    padding: 0px;
}

.row>:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.spot {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/*
      This is the outer svg wrapper that the SVG itself will 
      fill. Multiple svg-wrapper classes can be put side by side.
  */

.svg-wrapper {
    margin-top: 0;
    position: relative;
    width: 150px;
    /*make sure to use same height/width as in the html*/
    height: 40px;
    display: inline-block;
    border-radius: 3px;
    margin-left: 5px;
    margin-right: 5px
}

/*
    This is where we define the fill, color, thickness,
    and stroke pattern of the SVG when there is no hover.
    The dasharray and offset together define the line position
    under the words. Here's also where the transition speed is set.
  */

#shape {
    stroke-width: 6px;
    fill: transparent;
    stroke: #009FFD;
    stroke-dasharray: 85 400;
    stroke-dashoffset: -220;
    transition: 1s all ease;
}

/* 
      Pushing the text up into the SVG. Without this the
      text would be below the div's.
  */

#text {
    margin-top: -35px;
    text-align: center;
}

#text a {
    color: black;
    text-decoration: none;
    font-weight: 100;
    font-size: 1.1em;
}

/* 
      Changing the shape on hover. I change the color of the stroke,
  make it thinner, then set it to again wrap around the entire parent element.
  */

.svg-wrapper:hover #shape {
    stroke-dasharray: 50 0;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #06D6A0;
}

.experienceDivider {
    height: 4vh;
    background-color: rgb(10, 10, 10)
}
#projects {
    height: 8vh;
    background-color: rgba(10, 10, 10, 1);
}

.projectsTitle {
    margin-left: 12vw;
    color: rgb(255, 255, 255);
    font-size: 70px;
    padding: 0px;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 5px;
}

.backgroundColorProjects {
    height: 17vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, rgba(10, 10, 10, 1) 0%, rgba(244, 52, 250, 1) 100%);
    background: rgb(233, 71, 238);
    border-radius: 50px;
    width: 80vw;
    margin: auto;
}

.blackBackground {
    background-color: rgb(10, 10, 10);
}

.transitionColor {
    background: rgb(10, 10, 10);
    background: linear-gradient(180deg, rgba(10, 10, 10, 1) 0%, rgba(244, 52, 250, 1) 100%);
    height: 5vh;
}

.projectsContainer {
    display: flex;
    justify-content: center;
    padding-right: 0 !important;
    padding-top: 6vh;
    background-color: rgb(10, 10, 10);
}

.projectsContainerInner {
    width: 80%;
}

.row>* {
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 20px;
    margin-bottom: 30px;
    padding: 0px;
}

.row>:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.spot {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/*
      This is the outer svg wrapper that the SVG itself will 
      fill. Multiple svg-wrapper classes can be put side by side.
  */

.svg-wrapper {
    margin-top: 0;
    position: relative;
    width: 150px;
    /*make sure to use same height/width as in the html*/
    height: 40px;
    display: inline-block;
    border-radius: 3px;
    margin-left: 5px;
    margin-right: 5px
}

/*
    This is where we define the fill, color, thickness,
    and stroke pattern of the SVG when there is no hover.
    The dasharray and offset together define the line position
    under the words. Here's also where the transition speed is set.
  */

#shape {
    stroke-width: 6px;
    fill: transparent;
    stroke: #009FFD;
    stroke-dasharray: 85 400;
    stroke-dashoffset: -220;
    transition: 1s all ease;
}

/* 
      Pushing the text up into the SVG. Without this the
      text would be below the div's.
  */

#text {
    margin-top: -35px;
    text-align: center;
}

#text a {
    color: black;
    text-decoration: none;
    font-weight: 100;
    font-size: 1.1em;
}

/* 
      Changing the shape on hover. I change the color of the stroke,
  make it thinner, then set it to again wrap around the entire parent element.
  */

.svg-wrapper:hover #shape {
    stroke-dasharray: 50 0;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #06D6A0;
}

.projectsDivider {
    height: 6vh;
    background-color: rgb(10, 10, 10)
}
.aboutContainer {
    height: 100vh;
    background-color: aqua;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.gradPic {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.aboutDescriptionDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background-color: rgb(0, 0, 0, 0.75);
    right: 13vw;
    width: 32vw;
}

.aboutHeading {
    color: white;
    padding: 20px 70px;
    font-size: 45px;
    text-align: center;
}

.aboutText {
    color: white;
    font-size: 18px;
    padding: 20px 30px;
    text-align: center;
}

.jobTitleText {
    color: rgb(62, 215, 229);
}

@media (max-width: 1080px) {
    .aboutHeading {
        font-size: 32px;
    }

    .aboutText {
        font-size: 16px;
    }

    .aboutDescriptionDiv {
        right: 10vw;
        width: 40vw;
    }

    .gradPic {
        content: url(/static/media/kevingradpiccrop.65422d46.jpg);
    }

    .aboutContainer {
        height: 140vh;
    }
}


@media (max-width: 850px) {

    .aboutDescriptionDiv {
        right: 18vw;
        width: 60vw;
    }

    .gradPic {
        content: url(/static/media/kevingradpiccrop.65422d46.jpg);
    }
}
.footerContainer {
    padding: 30px;
    background-color: rgb(60, 60, 60);
    text-align: center;
}

.footerText {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: white;
}
html {
    scroll-behavior: smooth;
}

body {
    width: 100%;
}

.divider {
    height: 12em;
}

.columns {
    display: flex;
    flex-direction: column;
    align-items: center;
}
