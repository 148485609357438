@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');



.experienceTitle {
    margin-left: 12vw;
    color: rgb(255, 255, 255);
    font-size: 70px;
    padding: 0px;
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 5px;
}

.line {
    color: rgb(144, 144, 144);
    width: 76vw;
    border-top: 3px solid rgb(144, 144, 144);
    padding: 0px;
    margin: 0px;
    margin-left: 12vw;
}

.blackBackgroundExperience {
    background: linear-gradient(0deg, rgba(10, 10, 10, 1) 0%, rgb(14, 89, 111) 100%);
}

.blackSpace {
    height: 17vh;
    background-color: rgb(10, 10, 10);
}

.backgroundColorExperience {
    height: 17vh;
    background: linear-gradient(0deg, rgb(14, 89, 111) 0%, rgb(117, 223, 255) 100%);
}

.experienceContainer {
    display: flex;
    justify-content: center;
    padding-right: 0 !important;
    padding-top: 6vh;
    background-color: rgb(10, 10, 10);
}

.experienceContainerInner {
    width: 80%;
}

.row>* {
    transition: all .2s ease-in-out;
    cursor: pointer;
    margin: 20px;
    margin-bottom: 30px;
    padding: 0px;
}

.row>:hover {
    transform: scale(1.05);
}

.spot {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/*
      This is the outer svg wrapper that the SVG itself will 
      fill. Multiple svg-wrapper classes can be put side by side.
  */

.svg-wrapper {
    margin-top: 0;
    position: relative;
    width: 150px;
    /*make sure to use same height/width as in the html*/
    height: 40px;
    display: inline-block;
    border-radius: 3px;
    margin-left: 5px;
    margin-right: 5px
}

/*
    This is where we define the fill, color, thickness,
    and stroke pattern of the SVG when there is no hover.
    The dasharray and offset together define the line position
    under the words. Here's also where the transition speed is set.
  */

#shape {
    stroke-width: 6px;
    fill: transparent;
    stroke: #009FFD;
    stroke-dasharray: 85 400;
    stroke-dashoffset: -220;
    transition: 1s all ease;
}

/* 
      Pushing the text up into the SVG. Without this the
      text would be below the div's.
  */

#text {
    margin-top: -35px;
    text-align: center;
}

#text a {
    color: black;
    text-decoration: none;
    font-weight: 100;
    font-size: 1.1em;
}

/* 
      Changing the shape on hover. I change the color of the stroke,
  make it thinner, then set it to again wrap around the entire parent element.
  */

.svg-wrapper:hover #shape {
    stroke-dasharray: 50 0;
    stroke-width: 3px;
    stroke-dashoffset: 0;
    stroke: #06D6A0;
}

.experienceDivider {
    height: 4vh;
    background-color: rgb(10, 10, 10)
}