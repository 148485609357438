@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.languageText {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid;
    border-radius: 10px;
}